import styled, { createGlobalStyle } from 'styled-components';

import { device } from '../constants/breakpoints';

import fontRegular from '../assets/fonts/Roboto-Regular.ttf';
import fontHeading from '../assets/fonts/Gelion-SemiBold.ttf';

const Typography = createGlobalStyle`

  @font-face {
    font-family: 'Roboto';
    src: url(${fontRegular});
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Gelion';
    src: url(${fontHeading});
    font-style: normal;
    font-weight: 600;
  }
`;

const Headline = styled.h1`
  font-family: 'Gelion';
  letter-spacing: -0.5px;
  font-size: 1.6em;
  font-weight: 600;
  line-height: 1.2em;

  @media ${device.tablet} {
    font-size: 2em;
  }
`;

const Title = styled.h2`
  font-size: 1.5em;
  font-weight: 600;
`;

const Subtitle = styled.h3`
  font-size: 1.125em;
  font-weight: 400;
`;

const Paragraph = styled.p`
  font-size: 0.8em;
  font-weight: 400;
`;

export { Typography, Headline, Title, Subtitle, Paragraph };
