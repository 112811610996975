const breakpoints = {
  mobile: '360px',
  tablet: '768px',
  desktop: '1280px',
};

export const device = {
  mobile: `(min-width: ${breakpoints.mobile})`,
  tablet: `(min-width: ${breakpoints.tablet})`,
  desktop: `(min-width: ${breakpoints.desktop})`,
};

export default { breakpoints, device };
