import React from 'react';
import { ThemeProvider } from 'styled-components';

import GlobalStyles from '../styles/GlobalStyles';
import { PageLayout } from '../styles/PageLayout';
import { Typography } from '../styles/Typography';
import theme from '../constants/theme';

const Page = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <ThemeProvider theme={theme}>
        <PageLayout>{children}</PageLayout>
      </ThemeProvider>
    </>
  );
};

export default Page;
