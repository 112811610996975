import styled from 'styled-components';

import { device } from '../constants/breakpoints';

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: ${(props) => props.theme.background};
  padding: 0 1.5em;

  color: ${(props) => props.theme.textPrimary};
  width: 100%;
  height: 100vh;

  @media ${device.tablet} {
    background-color: #fafbff;
    padding: 0;
  }

  /* @media ${device.tablet} {
    &:after {
      background: ${(props) => props.theme.background};
      bottom: 0;
      content: '';
      display: block;
      height: 80%;
      left: 0;
      position: absolute;
      right: 0;
      transform: skewY(-5deg);
      transform-origin: 100%;
      z-index: -1;
    }
  } */
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    align-items: flex-start;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  background: #ffffff;

  @media ${device.tablet} {
    width: 34em;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: 2em 4em;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: rgba(253, 235, 235, 1);
  border-radius: 6px;
  color: rgba(236, 58, 53, 0.8);

  padding: 0.75em 0.75em;

  width: 100%;

  @media ${device.tablet} {
    width: 27em;
    padding: 0.75em 1em;
  }
`;

export { PageLayout, Container, Header, ErrorContainer };
